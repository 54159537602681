import Vue from "vue"
import Vuex from "vuex"
import {
  getToken,
  setToken,
  removeToken,
  setTokenExpTime,
  removeTokenExpTime,
  setCurrentLoginTime,
  removeCurrentLoginTime,
  getIdentifierToken,
  setIdentifierToken,
  removeIdentifierToken,
  getWebNameToken,
  setWebNameToken,
  removeWebNameToken,
  getWebAvaterToken,
  setWebAvaterToken,
  removeWebAvaterToken,
} from "@/utils/auth"
import { login, logout } from "@/api/login"
import { getuserinfo, refreshToken } from "@/api/user"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    username: getWebNameToken(),
    useravater: getWebAvaterToken(),
    identifier: getIdentifierToken(),
    token: getToken(),
    menuactiveid: window.localStorage.getItem("menuId") ?
      parseInt(window.localStorage.getItem("menuId")) :
      0,
    noticeFlag: true,
    show: false,
    percentage: 0,
    websiteData: {}
  },
  mutations: {
    SET_MENU_ID: (state, id) => {
      state.menuactiveid = id
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_Identifier: (state, token) => {
      state.identifier = token
    },

    SET_Username: (state, token) => {
      state.username = token
    },

    SET_Useravater: (state, token) => {
      state.useravater = token
    },
    changeNoticeFlag: (state, bool) => {
      state.noticeFlag = bool
    },
    SET_SHOW (state, data) {
      state.show = data
    },
    SET_PERCENTAGE (state, data) {
      state.percentage = data
    },
    SET_Website_Data (state, data) {
      state.websiteData = data
    }
  },
  actions: {
    setmenuid ({ commit }, id) {
      commit("SET_MENU_ID", id)
    },
    // 用户登录 获取token
    login ({ commit }, userInfo) {
      /* 先清空缓存 */
      // 重置按钮id
      commit("SET_MENU_ID", 0)
      window.localStorage.removeItem("menuId")
      //清除token
      commit("SET_TOKEN", "")
      removeToken()
      //清除用户身份信息
      commit("SET_Identifier", "")
      removeIdentifierToken()
      // dispatch('tagsView/delAllViews', null, {
      //   root: true
      // })
      //清除用户信息
      commit("SET_Username", "")
      removeWebNameToken()
      commit("SET_Useravater", "")
      removeWebAvaterToken()
      /* 获取用户登录信息 */
      const { username, password } = userInfo // 解构数据
      return new Promise((resolve, reject) => {
        // 执行退出方法
        login({ mobile: username, password: password, }).then((response) => {
          // code：408-用户手机号错误
          const { data } = response
          console.log(data)
          //重置按钮id
          commit("SET_MENU_ID", 0)
          window.localStorage.setItem("menuId", 0)
          // 登录成功保存Token
          commit("SET_TOKEN", data.access_token) // 登录成功保存token到vuex全局state
          setToken(data.access_token) // 保Token存到cookie 字段为Admin-Token
          setTokenExpTime(data.expires_in) // Token 有效期
          setCurrentLoginTime(data.create_time) // token记录的登录时间
          // 保存用户身份
          commit("SET_Identifier", data.user_identity) //保存用户身份信息到vuex全局state
          setIdentifierToken(data.user_identity) // 保存用户身份存到cookie
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    // 用户退出清除token及其他信息
    logout ({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
        // 执行退出方法
        logout().then(() => {
          //重置按钮id
          commit("SET_MENU_ID", 0)
          window.localStorage.removeItem("menuId")
          //清除token
          commit("SET_TOKEN", "")
          removeToken()
          removeTokenExpTime()
          removeCurrentLoginTime()
          //清除用户身份信息
          commit("SET_Identifier", "")
          removeIdentifierToken()
          //清除用户信息
          removeWebNameToken()
          removeWebAvaterToken()
          // dispatch('tagsView/delAllViews', null, {
          //   root: true
          // })
          resolve() // 返回信息
        }).catch((error) => {
          reject(error) // 抛出报错
        })
      })
    },
    // 更新Token
    refreshToken ({ commit }) {
      return new Promise((resolve, reject) => {
        refreshToken().then((res) => {
          console.log('更新token', res)
          setToken(res.data.access_token) // 登录成功保存token到cookie 字段为Admin-Token
          setTokenExpTime(res.data.expires_in) // token有效期
          setCurrentLoginTime(res.data.create_time) // token记录的登录时间
          const { data } = res.data
          commit("SET_TOKEN", data.access_token) // 登录成功保存token到vuex全局state
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    },

    //获取用户信息
    getuserinfo (params) {
      getuserinfo(params).then((response) => {
        if (response.code === 0) {
          commit("SET_Username", response.data.name)
          setWebNameToken(response.data.name)
          commit("SET_Useravater", response.data.avatar)
          setWebAvaterToken(response.data.avatar)
        }
      }).catch((error) => { })
    },
  },
  modules: {},
})