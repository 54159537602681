<template>
  <div class="footer">
    <div class="top-links">
      <!--logo-->
      <div class="left-logo">
        <el-image class="elimage" :src="info.portal_end_logo" />
      </div>
      <!--友情链接-->
      <div class="right-links">
        <el-carousel
          ref="carousel"
          class="simulation-swiper"
          :interval="10000"
          indicator-position="none"
          arrow="always"
        >
          <el-carousel-item
            class="simulation-item"
            v-for="(itmData, inx) in resLinkList"
            :key="inx"
          >
            <div class="item-page_cont">
              <section
                class="simulation-item-section"
                v-for="(item, index) in itmData"
                :key="index"
              >
                <a :href="item.href" :target="item.is_open?'_blank':'_self'" class="cur_a">
                  <div class="icons-cont">
                    <el-image class="elimage" :src="item.logo" />
                  </div>
                  <div class="item-text">{{ item.title }}</div>
                </a>
              </section>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="bottom-info">
      <div class="item">地址：{{ info.address }} 电话：{{ info.phone }} 邮编：{{ info.postal_code }}</div>
      <div class="item icp_cont">
        <a :href="info.icp_filing_url" style="color:#fff;">{{ info.icp_filing_num }}</a>
        <a :href="info.net_filing_url" style="color:#fff">
          <div class="foot_icon"></div>
          {{ info.net_filing }}
        </a>
      </div>
      <div class="item">{{ info.copyright }} 版权所有 技术支持：{{ info.support }}</div>
    </div>
  </div>
</template>

<script>
import { getLinkList } from "@/api/user"
import { mapState } from 'vuex'
export default {
  name: "Footer",
  components: {},
  data () {
    return {
      info: {
        portal_end_logo: '',
        address: "湖北省武汉市洪山区狮子山街1号",
        postal_code: "430070",
        phone: "027-87285075",
        copyright: "CopyRight ©2021 华中农业大学",
        support: "武汉有教文化传播有限公司",
        icp_filing_num: "鄂ICP备13013419-1号",
        icp_filing_url: "https://beian.miit.gov.cn/#/Integrated/index",
        net_filing: "鄂公网安备 42011102000808号",
        net_filing_url: "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42011102000808",
      },
      linkList: [
        {
          logo: require("@/assets/footer/1.png"),
          href: "http://www.hzau.edu.cn/",
          title: "通道",
          is_open: 0,
        },
        {
          logo: require("@/assets/footer/2.png"),
          href: "",
          title: "通道",
          is_open: 0,
        },
        {
          logo: require("@/assets/footer/3.png"),
          href: "",
          title: "通道",
          is_open: 0,
        },
        {
          logo: require("@/assets/footer/4.png"),
          href: "",
          title: "通道",
          is_open: 0,
        },
        {
          logo: require("@/assets/footer/5.png"),
          href: "",
          title: "通道",
          is_open: 0,
        },
        {
          logo: require("@/assets/footer/6.png"),
          href: "",
          title: "通道",
          is_open: 0,
        },
        {
          logo: require("@/assets/footer/7.png"),
          href: "",
          title: "通道",
          is_open: 0,
        },
        {
          logo: require("@/assets/footer/8.png"),
          href: "",
          title: "通道",
          is_open: 0,
        },
        {
          logo: require("@/assets/footer/9.png"),
          href: "",
          title: "通道",
          is_open: 0,
        },
        {
          logo: require("@/assets/footer/10.png"),
          href: "",
          title: "通道",
          is_open: 0,
        }
      ],
      resLinkList: [],
    }
  },
  computed: {
    ...mapState(['websiteData']),
  },
  watch: {
    websiteData: {
      handler (newV, oldV) {
        this.setFooterInfo(newV)
      },
      deep: true
    }
  },

  created () {
    this.init()
  },
  mounted () { },
  methods: {
    setFooterInfo (data) {
      this.info = data
    },
    init () {
      getLinkList().then(res => {
        if (res.code === 0) {
          let dataList = res?.data ?? this.linkList
          let resList = []
          for (let i = 0; i < dataList.length; i += 10) { // 拆分数据每10个一组
            resList.push(dataList.slice(i, i + 10))
          }

          if (dataList.length > 20) {
            this.resLinkList = resList
          } else { // carousel如果少于两组会左右切换，叠加数据
            this.resLinkList = resList.concat(resList)
          }
        }
      }).catch(() => { })
    },
  }
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 640px) {
  .footer {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    left: 0;
    overflow: hidden;

    .top-links {
      flex-direction: column;
      align-items: center;
      height: 230px;
      width: 100%;
      .left-logo {
        border-right: none;
        .elimage {
          height: calc(100% - 80px);
        }
      }
    }
    .bottom-info {
      height: calc(100% - 220px);
    }

    .item {
      font-size: 15px;
      font-weight: 400;
      color: #fff;
      text-align: center;

      .foot_icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url('../assets/footicon.png') 0 0 no-repeat;
        background-size: 100% 100%;
        margin: 0px 8px;
      }
    }
  }
  .right-links {
    width: 100%;
    ::v-deep .el-carousel__container {
      height: 100px;
    }

    .item-page_cont {
      padding-top: 10px;
      align-items: flex-start;
      .simulation-item-section {
        width: calc(100% / 10);
      }
    }
    .icons-cont {
      width: 36px;
      height: 36px;
      .elimage {
        width: 22px;
        height: 22px;
      }
    }
    .item-text {
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      word-break: break-all;
    }
  }
}

@media screen and (min-width: 640px) and (max-width: 1200px) {
  .footer {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    left: 0;
    overflow: hidden;

    .top-links {
      flex-direction: column;
      align-items: center;
      height: 250px;
      width: 100%;
      .left-logo {
        border-right: none;
        .elimage {
          // margin: 10px auto;
          // height: calc(100% - 20px);
        }
      }
    }

    .bottom-info {
      height: calc(100% - 260px);
    }

    .item {
      font-size: 12px;
      font-weight: 400;
      color: #fff;
      text-align: center;

      .foot_icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url('../assets/footicon.png') 0 0 no-repeat;
        background-size: 100% 100%;
        margin: 0px 8px;
      }
    }
  }
  .right-links {
    width: 90%;
    ::v-deep .el-carousel__container {
      height: 100px;
    }
    .item-page_cont {
      padding-top: 4px;
      .simulation-item-section {
        width: calc(100% / 10);
      }
    }
    .icons-cont {
      width: 56px;
      height: 56px;
      .elimage {
        width: 24px;
        height: 24px;
      }
    }
    .item-text {
      font-size: 14px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      white-space: nowrap;
    }
  }
}

@media screen and (min-width: 1200px) {
  .footer {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    left: 0;
    overflow: hidden;

    .top-links {
      flex-direction: row;
    }

    .item {
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      text-align: center;

      .foot_icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url('../assets/footicon.png') 0 0 no-repeat;
        background-size: 100% 100%;
        margin: 0px 8px;
      }
    }
  }
  .top-links {
    height: 200px;
    // width: 80%;
    .left-logo {
      padding-left: 30px;
      .elimage {
        // margin: 30px auto;
        padding-right: 30px;
        // height: calc(100% - 60px);
        position: relative;
      }
      .elimage::before {
        content: '';
        width: 1px;
        height: 100px;
        position: absolute;
        right: 0px;
        border-right: 1px solid rgba(255, 255, 255, 0.2);
        margin-top: 40%;
        transform: translateY(-50%);
      }
    }
  }
  .bottom-info {
    height: calc(100% - 200px);
    .icp_cont {
      margin: 4px 0px;
    }
  }
  .right-links {
    // width: calc(100% - 215px);
    flex-basis: 1000px;
    margin-left: 10px;
    ::v-deep .el-carousel__container {
      height: 200px;
    }

    .item-page_cont {
      height: 100%;
      align-items: center;
      .simulation-item-section {
        width: 100px;
        .icons-cont {
          width: 70px;
          height: 70px;
          .elimage {
            width: 28px;
            height: 28px;
          }
        }
        .item-text {
          font-size: 14px;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          white-space: nowrap;
        }
      }
    }
  }
}

/*公共样式 */
.top-links {
  display: flex;
  margin: 0px auto;
  justify-content: center;
  .left-logo {
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    .elimage {
      object-fit: cover;
      // filter: brightness(0) invert(1);
    }
  }
  .right-links {
    ::v-deep .el-carousel__container {
      .el-carousel__arrow {
        display: none;
      }
    }
    .item-page_cont {
      display: flex;
      .simulation-item-section {
        display: flex;
        justify-content: center;
        .cur_a {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          width: 100%;
        }
        .cur_a:active {
          text-decoration: none;
        }
        .icons-cont {
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
        }
        .item-text {
          color: #fff;
          font-weight: 400;
          text-align: center;
        }
      }
    }
  }
}
.bottom-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 1px dashed rgba(255, 255, 255, 0.35);
  .icp_cont,
  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
