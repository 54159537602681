<template>
  <div class="main">
    <router-view></router-view>
    <div v-if="!flag && isMobile == 1" class="customer_service" @click="to_customer()">
      <div class="customer_service_cont">
        <div class="icons_cont">
          <img :src="customer_img" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const request_base_path = require("../utils/base_url");
export default {
  name: "Main",
  data() {
    return {
      beforeUnload_time: 0,
      gap_time: 0,
      height: {
        height: "",
      },
      customer_img: require("@/assets/customer/customer_service.png"),
      flag: false,
      isMobile: '' // 判断手机端字段
    };
  },
  computed: {
    menuid() {
      return this.$store.state.menuactiveid;
    },
  },
  watch: {
    $route: {
      handler(newVal) {
        let routeStr = "/home/livebroadcast/courselearning"
        if (newVal.path === routeStr) {
          this.flag = true
        } else {
          this.flag = false
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() { },
  created() {
    this.hh();
     if (this._isMobile()) {
      this.isMobile = 0
    } else {
      this.isMobile = 1
    }
  },
  methods: {
      // 判断手机端
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    hh() {
      this.height.height = window.innerHeight - 160 + "px";
    },
    to_customer() {
      let strUrl = ''
      if (process.env.NODE_ENV == 'development') {
        const routeData = this.$router.resolve({ path: '/customer'});
        strUrl = routeData.href;
        // strUrl = request_base_path.dev_url + '#/customer';
      } else if (process.env.NODE_ENV == 'production') {
        strUrl = request_base_path.pro_url + '#/customer';
      }
      window.open(strUrl, '_blank')
    }
  },
  destroyed() { },
};
</script>
<style scoped lang="scss">
.main {
  background: #ffffff;
  height: 100%;
  width: 100%;
  position: relative;

  .customer_service {
    width: 56px;
    height: 160px;
    background: #000;
    overflow: hidden;
    position: fixed;
    bottom: calc(120px + 15%);
    right: calc(-6px + 1%);
    cursor: pointer;
    z-index: 99;

    .customer_service_cont {
      width: 100%;
      height: 100%;
      position: relative;
      background: #fff;
    }

    .icons_cont {

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .el_main{
    padding: 0 !important;
  }
}
</style>
